<template>
  <div class="fancy-text-block-seven mt-180 md-mt-100">
    <div class="bg-wrapper">
      <div class="container">
        <span class="name font-effect-emboss">PORADNIA DERMATOLOGICZNA</span>
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
            <div class="img-holder mt-80">
              <img src="../../assets/images/logos_/white/pexels-karolina-grabowska-4210660.png" alt="">
              <img src="../../assets/images/shape/34.svg" alt="" class="shapes shape-six">
            </div>
          </div>
          <div class="col-xl-6 col-lg-7 ms-auto" data-aos="fade-left" data-aos-duration="1200">
            <div class="quote-wrapper pt-60">
              <blockquote class="font-rubik blockquote-color">
                <h6>Dr n.med. </h6>Bożena PECIAK-WIECZOREK
                <h6>Spec. Dermatolog</h6>
                <h6>Diagnostyka i leczenie dzieci i dorosłych</h6>
              </blockquote>
              <h6 class="mt-30"></h6>
              <blockquote class="font-rubik blockquote-color" style="text-align: left;">
                <h6 style="margin-left: 10%; color:beige;">CENNIK : </h6>
                <h6 style="margin-left: 10%;">Pierwsza wizyta 250,00 zł</h6>
                <h6 style="margin-left: 10%;">Wizyta kontrolna 220,00</h6>
                <h6 style="margin-left: 10%;">Dermatoskop 50-100 zł</h6>
                <h6 class="mt-20"></h6>
                <h6 style="margin-left: 10%; color:beige;">REJESTRACJA :</h6>
                <h6 style="margin-left: 10%;">tel.: 601 437 337 - lekarz</h6>
                <h6 style="margin-left: 10%;">tel.: 33 842 43 42 - Eskulap</h6>
              </blockquote>
              <h6 class="mt-20"></h6>
            </div> <!-- /.quote-wrapper -->
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'Dermatologiczna-main'
}
</script>