<template>
  <footer class="theme-footer-one pt-130 md-pt-70" id="footer">
    <div class="top-footer">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-lg-3 col-md-12 footer-about-widget" data-aos="fade-up" data-aos-duration="1200">
            <router-link to="/" class="logo">
              <img src="../../../assets/images/logos_/white/deski_06.svg" alt="" />
            </router-link>
          </div>
          <!-- /.about-widget -->
          <div class="col-lg-3 col-md-4 footer-list" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
            <h5 class="footer-title">Nasze dane :</h5>
            <ul>
              <li>"ESKULAP"</li>
              <li>Zespół Specjalistycznych</li>
              <li>Gabinetów Lekarskich</li>
              <li>32-600 Oświęcim</li>
              <li>Marii Skłodowskiej-Curie 7 /2</li>
              <li>NIP: 5492466065</li>
            </ul>
          </div>
          <!-- /.footer-list -->
          <div class="col-lg-1 col-md-4 footer-list" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
          </div>
          <!-- /.footer-list -->
          <div class="col-lg-5 col-md-4 address-list" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <h5 class="footer-title">Znajdź nas na mapie</h5>
            <iframe class="newsletter" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d538.7746901280198!2d19.24175373138052!3d50.032741968113335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471695089511ac01%3A0x2b79dfa8618d38cc!2sEskulap.%20Sp%C3%B3%C5%82dzielnia%20Lekarsko%20-%20Stomatologiczna!5e0!3m2!1spl!2spl!4v1673030860460!5m2!1spl!2spl" width="350" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <!-- /.footer-list -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </div>
    <!-- /.top-footer -->

    <div class="container">
      <div class="bottom-footer-content">
        <div class="d-sm-flex align-items-center justify-content-between">
          <p class="order-sm-first" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="100"
            data-aos-anchor=".theme-footer-one">
            <a href="https://hash.com.pl">HASH s.c.</a>
          </p>
        </div>
      </div>
      <!-- /.bottom-footer -->
    </div>
  </footer>
  <!-- /.theme-footer-one -->
</template>

<script>
export default {
  name: "FooterArea",
};
</script>