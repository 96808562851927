<template>
  <div class="fancy-text-block-seven mt-180 md-mt-100">
    <div class="bg-wrapper">
      <div class="container"><span class="name font-effect-emboss">PORADNIA KARDIOLOGICZNA</span>
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
            <div class="img-holder">
              <img src="../../assets/images/logos_/white/cardio.png" alt="">
              <img src="../../assets/images/shape/34.svg" alt="" class="shapes shape-six">
            </div>
          </div>
          <div class="col-xl-6 col-lg-7 ms-auto" data-aos="fade-left" data-aos-duration="1200">          
            <div class="quote-wrapper pt-60">
              <blockquote class="font-rubik blockquote-color">
                <h6>Dr n.med. </h6>Leszek RADZISZEWSKI
                <h6>Spec. kardiolog</h6> 
              </blockquote>
              <h6 class="mt-30">Górnośląskie Centrum Medyczne - Szpital w Ochojcu</h6>
            </div> <!-- /.quote-wrapper -->
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'Kardiologiczna-main'
}
</script>

